body {
  font: "Lato";
  height: 100vh;
  background-color: lightblue;
}

header {
  height: calc(100vh - 500px);
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 80px;
  color: #000000
}

header img {
  border: 2px dashed;
  border-radius: 50%;
}

header h3 {
  margin: 30px 0 10px 0;
}

section {
  margin: 40px 0;
}

section h4 {
  text-align: center;
}

section h5 {
  text-transform: uppercase;
}

.divider {
  height: 2px;
  width: 80%;
  background-color: #000;
  margin: 0 auto;
  margin-top: 60px;
}

#contact {
  margin-bottom: 100px;
}

footer {
  background-color: #000000;
  height: 50px;
  width: 100%;
  bottom: 0;
  text-align: center;
}

footer ul {}

footer ul li {
  display: inline;
  padding: 10px 50px;
  list-style-type: none;
  font-size: 22px;
}

.fb-link {
  color: #4267b2;
}

.gh-link {
  color: #fff;
}

.ln-link {
  color: #0073b1;
}

.tw-link {
  color: rgba(29, 161, 242, 1.00);
}

.profile-pic{
  height: 6em;
  height: 8em;
}