@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Space+Mono:ital,wght@0,700;1,400&display=swap');

/* font-family: 'Roboto', sans-serif; */


/* font-family: 'Space Mono', monospace; */

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0
}

header {
    color: white;
    display: block;
}

section {
    /* margin: 2rem; */
    /* padding: 2rem; */
}

section:last-of-type {
    margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Space Mono', monospace;
}

p {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
}

.hero-banner {
    /* background-image: url("./assets/banner.jpg");
    background-size: cover;
    background-image: no-repeat; */
    background: linear-gradient(rgba(9, 39, 235, 0.6), rgba(9, 39, 235, 0.6)), url("./assets/banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh;
    position: relative;
}

.hero-name {
    position: relative;
    top: -200px;
    font-size: 5.5rem;
    font-weight: 700;
    padding-left: 4rem;
}

.hero-footer {
    width: 100%;
    height: calc(100vh - 70%);
    background-color: #0927eb;
    position: absolute;
    bottom: 0;
}

.hero-footer h3 {
    font-size: 2.5rem;
    padding-left: 4rem;
    text-transform: uppercase;
}

.hero-arrows {
    float: right;
    padding-right: 12rem;
    top: -70px;
    position: relative;
}

.container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.item {
    flex: 1;
}

.about-col-left {
    padding: 0 4rem;
    border-left: 2px solid #0927eb;
}

.about-col-left h3 {
    color: #0927eb;
    font-weight: 700;
    font-size: 40px;
    margin: 0;
}

.img-me {
    background: linear-gradient(rgba(9, 39, 235, 0.6), rgba(9, 39, 235, 0.6)), url("./assets/me.jpg");
    width: 500px;
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    float: right;
    position: relative;
    right: -3.8rem;
}

.blue-arrows {
    width: 8%
}

.card-img {
    width: 200px;
    padding: .4rem;
    padding-left: 2rem;
    border-left: 2px solid #0927eb;
}

#kids-img{
    width: 18rem;
    margin: 0 auto;
    background: #0927eb;
}

#studio-img{
    width: 22rem;
    margin: 0 auto;
    background: #0927eb;
}

#live-img{
    width: 22rem;
    margin: 0 auto;
}

#bootcamps{
    width: 12rem;
    margin: 0 auto;
}

.card-title {
    padding-left: 2rem;
    color: #0927eb;
}

.card-info {
    padding-left: 2rem;
}

.label-text {
    padding: .6rem;
    background-color: #0927eb;
    color: #ffffff;
    text-transform: uppercase;
    display: inline-block;
    font-size: 2rem;
}

.img-contact {
    background: linear-gradient(rgba(9, 39, 235, 0.6), rgba(9, 39, 235, 0.6)), url("./assets/contact.jpg");
    width: 500px;
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    left: -3.8rem;
}

.contact-details {
    padding: 0 4rem;
    border-left: 2px solid #0927eb;
}

.contact-title {
    color: #0927eb;
    font-weight: 700;
    font-size: 40px;
    margin: 0;
}